import React, { useContext} from 'react';
import { CurrencyContext } from '../context/currencyContext';

const CoinData = ({ data }) => {
  const { currency } = useContext(CurrencyContext);

  const renderData = () => {
    if (data) {
      return (
        <>
          <div className="data__item">
            <span className="data__title">Volume (24h)</span>
            <span>{currency.symbol}{data.total_volume.toLocaleString('nl-BE')}</span>
          </div>
          <div className="data__item">
            <span className="data__title">Market Cap</span>
            <span>{currency.symbol}{data.market_cap.toLocaleString('nl-BE')}</span>
          </div>
          <div className="data__item">
            <span className="data__title">Circulating Supply</span>
            <span>{data.circulating_supply.toLocaleString('nl-BE',{maximumFractionDigits: 0})}</span>
          </div>
          <div className="data__item">
            <span className="data__title">Total Supply</span>
            <span>{data.total_supply ? data.total_supply.toLocaleString('nl-BE',{maximumFractionDigits: 0}) : data.total_supply}</span>
          </div>
          <div className="data__item">
            <span className="data__title">Low 24h</span>
            <span>{currency.symbol}{data.low_24h.toLocaleString('nl-BE',{maximumFractionDigits: 6})}</span>
          </div>
          <div className="data__item">
            <span className="data__title">High 24h</span>
            <span>{currency.symbol}{data.high_24h.toLocaleString('nl-BE',{maximumFractionDigits: 6})}</span>
          </div>
        </>
      );
    }
  };

  return (
    <div className="area data">{renderData()}</div>
  );
};

export default CoinData;
