import React, { useContext, useEffect, useState } from 'react';
import coinGecko from '../apis/coinGecko';
import { WatchListContext } from '../context/watchListContext';
import { CurrencyContext } from '../context/currencyContext';
import Coin from './Coin';

const CoinList = () => {
  const [coins, setCoins] = useState([]);
  const { watchList, deleteCoin } = useContext(WatchListContext); // hook
  const [isLoading, setIsLoading] = useState(false);

  const { currency } = useContext(CurrencyContext); // no need to add setCurrency as we will not use it in this component

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      // API from coingecko.com
      // https://www.coingecko.com/en/api#operations-coins-get_coins_markets
      const response = await coinGecko.get('/coins/markets', { // get() is function from axios
        params: {
          vs_currency: currency.name,
          ids: watchList.join(',')
        }
      });

      // get list of top 100 coins. Todo: feed this in AddCoin and WatchListContext
      // const test = await coinGecko.get('/coins/markets', {
      //   params: {
      //     vs_currency: currency.name,
      //     order: 'market_cap_desc',
      //   }
      // });
      // console.log(test.data.map(a => a.id));

      setCoins(response.data);
      setIsLoading(false);
    };

    if (watchList.length > 0) {
      fetchData();
    }
    else {
      setCoins([]);
    }
  }, [currency, watchList]); // run useEffect everytime currency or watchList changes

  const renderCoins = () => {
    if (isLoading) {
      return <div>Loading...</div>;
    }
    else {
      return (
        <ul className="coin-list">
          {coins.map(coin => {
            return <Coin key={coin.id} coin={coin} deleteCoin={deleteCoin} />
          })}
        </ul>
      );
    }
  };

  return (
    <>{renderCoins()}</>
  );
};

export default CoinList;
