import React, { createContext, useEffect, useState } from 'react';

export const WatchListContext = createContext();

export const WatchListContextProvider = props => {
  const [watchList, setWatchList] = useState(
    ((localStorage.getItem('watchList') !== null) && localStorage.getItem('watchList').split(',')) || ['bitcoin', 'ethereum', 'ripple', 'cardano', 'solana', 'polkadot', 'cosmos', 'algorand', 'stellar'] // default list on first page view
  );

  useEffect(() => {
    localStorage.setItem('watchList', watchList);
  }, [watchList]); // run everytime watchList changes

  const addCoin = coin => {
    if (watchList.indexOf(coin) === -1) { // not in list
      setWatchList([...watchList, coin]);
    }
  };

  const deleteCoin = (coin) => {
    setWatchList(watchList.filter(el => {
      return el !== coin;
    }));
  };

  return (
    <WatchListContext.Provider value={{ watchList, deleteCoin, addCoin }}>
      { props.children }
    </WatchListContext.Provider>
  );
};