import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { CurrencyContext } from '../context/currencyContext';

const Coin = ({ coin, deleteCoin }) => { // {} to destructure it
  const { currency } = useContext(CurrencyContext);

  return (
    <li>
      <Link to={`/coins/${coin.id}`} className="coin-list__link">
        <img src={coin.image} alt={`Logo ${coin.id}`} width="48" height="48" className="coin-list__image" />
        <span className="coin-list__name">{coin.name}<span className="coin-list__symbol"> ({coin.symbol.toUpperCase()})</span></span>
        <span className="coin-list__price">{currency.symbol}{coin.current_price.toLocaleString('nl-BE')}</span>
        <span className={coin.price_change_percentage_24h < 0 ? 'coin-list__percentage text--fail' : 'coin-list__percentage text--success'}>
          {coin.price_change_percentage_24h < 0 ? (<i className="arrow fas fa-sort-down"></i>) : (<i className="arrow fas fa-sort-up"></i>)}
          {coin.price_change_percentage_24h.toLocaleString('nl-BE', {minimumFractionDigits: 2, maximumFractionDigits: 2})}%
        </span>
        <i onClick={(e) => {
          e.preventDefault();
          deleteCoin(coin.id);
        }} className="coin-list__delete fas fa-times-circle"></i>
      </Link>
    </li>
  );
};

export default Coin;
