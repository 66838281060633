import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import HistoryChart from '../components/HistoryChart';
import CoinData from '../components/CoinData';
import coinGecko from '../apis/coinGecko';
import { CurrencyContext } from '../context/currencyContext';

const CoinDetailPage = () => {
  const { id } = useParams(); // hook to get coin id from parameter in url
  const [coinData, setCoinData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  
  const { currency } = useContext(CurrencyContext); // get value from CurrencyContext
  
  const formatData = (data) => {
    return data.map((el) => {
      return {
        // x: moment(el[0]).format(), import moment from 'moment'; seems not needed anymore
        x: el[0],
        y: el[1].toFixed(3) // not enough to see eg. Shiba Inu
      }
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      // https://www.coingecko.com/en/api#operations-coins-get_coins__id__market_chart
      // https://www.coingecko.com/en/api#operations-coins-get_coins_markets
      const [day, week, month, year, all, detail] = await Promise.all([
        coinGecko.get(`/coins/${id}/market_chart/`, {
          params: {
            vs_currency: currency.name,
            days: '1'
          }
        }),
        coinGecko.get(`/coins/${id}/market_chart/`, {
          params: {
            vs_currency: currency.name,
            days: '7'
          }
        }),
        coinGecko.get(`/coins/${id}/market_chart/`, {
          params: {
            vs_currency: currency.name,
            days: '30'
          }
        }),
        coinGecko.get(`/coins/${id}/market_chart/`, {
          params: {
            vs_currency: currency.name,
            days: '365'
          }
        }),
        coinGecko.get(`/coins/${id}/market_chart/`, {
          params: {
            vs_currency: currency.name,
            days: 'max'
          }
        }),
        coinGecko.get(`/coins/markets/`, {
          params: {
            vs_currency: currency.name,
            ids: id
          }
        })
      ]);

      setCoinData({
        day: formatData(day.data.prices),
        week: formatData(week.data.prices),
        month: formatData(month.data.prices),
        year: formatData(year.data.prices),
        all: formatData(all.data.prices),
        detail: detail.data[0]
      });
      setIsLoading(false);
    };

    fetchData();
    // eslint-disable-next-line
  },[]); // [] -> run once, no need to rerun as currency or id is not updated on this page (https://reactjs.org/docs/hooks-effect.html#tip-optimizing-performance-by-skipping-effects)

  const renderData = () => {
    if (isLoading) {
      return <div>Loading...</div>;
    }
    else {
      return (
        <div>
          <HistoryChart data={coinData} />
          <CoinData data={coinData.detail} />
        </div>
      );
    }
  };
  
  return renderData();
};

export default CoinDetailPage;
