import React from 'react';
import AddCoin from '../components/AddCoin';
import CoinList from '../components/CoinList';
import ToggleCurrency from '../components/ToggleCurrency';

const CoinSummaryPage = () => {
  return (
    <div className="area">
      <div className="buttons">
        <AddCoin />
        <ToggleCurrency />
        <button onClick={() => window.location.reload(false)} aria-label="Reload page" className="btn"><i className="fas fa-redo"></i></button>
      </div>
      <CoinList />
    </div>
  );
};

export default CoinSummaryPage;
