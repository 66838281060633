import React, { createContext, useEffect, useState } from 'react';

export const CurrencyContext = createContext(null); // no initial value

export const CurrencyContextProvider = props => {
  const [currency, setCurrency] = useState( // set initial value
    ((localStorage.getItem('currency') !== null) && JSON.parse(localStorage.getItem('currency'))) || { name: 'usd', symbol: '$' }
  );

  useEffect(() => {
    localStorage.setItem('currency', JSON.stringify(currency));
  }, [currency]); // run everytime currency changes

  return ( // could also be defined directly in App.jsx
    <CurrencyContext.Provider value={{ currency, setCurrency }}>
      { props.children }
    </CurrencyContext.Provider>
  );
};
