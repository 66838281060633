import React from 'react';

const Header = () => {
  return (
    <>
      <h1>Crypto Tracker</h1>
    </>
  );
};

export default Header;
