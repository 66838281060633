export const historyOptions = {
  responsive: true,
  maintainAspectRatio: false,
  animation: {
    duration: 1000
  },
  plugins: {
    legend: {
      display: true,
      title: {
        display: false,
        text: 'legend'
      },
      labels: {
        color: 'rgb(0, 0, 0)'
      },
      onClick: () => {} // overwrite default behavior
    },
    title: {
      display: false,
      text: 'title'
    },
    tooltip: {
      intersect: false
    }
  },
  scales: {
    x: {
      type: 'time',
      ticks: {
        color: 'rgb(0, 0, 0)',
        font: {
          size: 10
        }
      }
    },
    y: {
      display: true,
      title: {
        display: false,
        text: 'value'
      }
    }
  }
};