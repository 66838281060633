import React, { useContext, useEffect, useRef, useState } from 'react';
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-date-fns'; // required to install seperately since Chartjs 3
// import 'chartjs-adapter-moment'; // heavier alternative to date-fns
import { historyOptions } from '../chartConfigs/chartConfigs';
import { CurrencyContext } from '../context/currencyContext';
Chart.register(...registerables); // new since Chartjs 3 as it is tree-shakeable

const HistoryChart = ({ data }) => {
  const chartRef = useRef(); // React alternative for document.getElementById()
  const { day, week, month, year, all, detail } = data;
  const [timeFormat, setTimeFormat] = useState('30d');

  const { currency } = useContext(CurrencyContext);

  const determineTimeFormat = () => {
    switch (timeFormat) {
      case '24h':
        return day;
      case '7d':
        return week;
      case '30d':
        return month;
      case '1y':
        return year;
      case 'all':
        return all;
      default:
        return day;
    }
  };

  useEffect(() => { // run when component loads up
    if (chartRef && chartRef.current && detail) { // canvas defined and rendered
      const chartInstance = new Chart(chartRef.current, {
        type: 'line',
        data: {
          datasets: [
            {
              label: `${detail.name} price`,
              data: determineTimeFormat(),
              backgroundColor: 'hsl(210, 100%, 45%, .2)',
              fill: 'origin',
              borderColor: 'hsl(210, 100%, 45%)',
              borderWidth: 2,
              pointRadius: 0,
              pointHitRadius: 20, // sensitivity of tooltip
            }
          ]
        },
        options: {...historyOptions}
      });
      return () => chartInstance.destroy();
    }
  }); // or [determineTimeFormat], works the same

  const renderPrice = () => {
    if (detail) {
      return (
        <>
          <span>{currency.symbol}{detail.current_price.toLocaleString('nl-BE', {maximumFractionDigits: 6})}</span>
          <span className={detail.price_change_percentage_24h < 0 ? 'text--fail' : 'text--success'}>
            {detail.price_change_percentage_24h < 0 ? (<i className="arrow fas fa-sort-down"></i>) : (<i className="arrow fas fa-sort-up"></i>)}
            {detail.price_change_percentage_24h.toLocaleString('nl-BE', {minimumFractionDigits: 2, maximumFractionDigits: 2})}%
          </span>
        </>
      );
    }
  };

  const buttonTypes = ['24h', '7d', '30d', '1y', 'all']; // add others in CoinDetailPage.jsx

  return (
    <div className="area">
      <div>
        <canvas ref={chartRef} id="myChart" width={400} height={600}></canvas>
      </div>
      <div className="chart__footer">
        <div className="chart__data">{renderPrice()}</div>
        <div className="chart__button-group">
          {buttonTypes.map(type => (
            <button key={type} onClick={() => setTimeFormat(type)} className={timeFormat === type ? 'btn active' : 'btn'}>{type}</button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HistoryChart;
