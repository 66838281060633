import React, { useContext } from 'react';
import { CurrencyContext } from '../context/currencyContext';

const ToggleCurrency = () => {
  const { currency, setCurrency } = useContext(CurrencyContext); // no need to add setCurrency as we will not use it in this component

  return (
    <>
      <button onClick={() => currency.name === 'usd' ? setCurrency({ name: 'eur', symbol: '€' }) : setCurrency({ name: 'usd', symbol: '$' })} className="btn">Toggle currency</button>
    </>
  );
};

export default ToggleCurrency;
