import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import CoinDetailPage from "./pages/CoinDetailPage";
import CoinSummaryPage from "./pages/CoinSummaryPage";
import Header from "./components/Header";
import './app.css';
import { WatchListContextProvider } from './context/watchListContext';
import { CurrencyContextProvider } from './context/currencyContext';

const App = () => {
  return (
    <div className="container">
      <CurrencyContextProvider>
        <WatchListContextProvider>
          <BrowserRouter>
            <Header />
            <Route exact path="/" component={ CoinSummaryPage } />
            <Route path="/coins/:id" component={ CoinDetailPage } />
          </BrowserRouter>
        </WatchListContextProvider>
      </CurrencyContextProvider>
    </div>
  );
};

export default App;
