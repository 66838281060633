import React, { useContext, useState } from 'react';
import { WatchListContext } from '../context/watchListContext';

const AddCoin = () => {
  // see "top_cryptos.xlsx" - also update list in WatchListContext
  // check name at https://www.coingecko.com/en/coins/
  const availableCoins = ['bitcoin', 'ethereum', 'ripple', 'cardano', 'solana', 'avalanche-2', 'polkadot', 'matic-network', 'cosmos', 'uniswap', 'chainlink', 'tron', 'algorand', 'stellar', 'waves', 'tezos', 'eos', 'flow', 'mina-protocol', 'compound-governance-token', 'qtum', 'kava', 'moonbeam', 'livepeer', 'kyber-network-crystal', 'sushi', 'astar', 'power-ledger', 'nano'];
  const [isActive, setIsActive] = useState(false);
  const { addCoin, watchList } = useContext(WatchListContext); // add access to addCoin function and watchList array

  const updatedCoinList = availableCoins.filter(coin => !watchList.includes(coin));

  const handleClick = (coin) => {
    addCoin(coin);
    setIsActive(false);
  }

  return (
    <>
      <button onClick={() => setIsActive(!isActive)} className={isActive ? 'btn dropdown__toggle active' : 'btn dropdown__toggle'}>Add coin</button>
      <div className={isActive ? 'dropdown__menu show' : 'dropdown__menu'}>
        {updatedCoinList.map((el) => {
          return (
            // eslint-disable-next-line
            <a key={el} href="#" onClick={() => handleClick(el)} className="dropdown__item">{el}</a>
          );
        })}
      </div>
    </>
  );
};

export default AddCoin;
